import React from "react";
import PuffLoader from "react-spinners/PuffLoader";

function PageSpinner(props) {

  let color = "#1081E8";

  return (
    props.spinner && props.spinner.length ?
      <div className="spinner-layer" >
        <div className="sweet-loading">
          <PuffLoader size={80} color={color}/>
        </div>
      </div>
      : null
  );
}

export default PageSpinner;