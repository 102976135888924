import React, { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import { Link, withRouter } from "react-router-dom"
import Breadcrumbs from './../../../../components/Common/Breadcrumb'
import PropTypes from "prop-types"
import ModalComponent from './../../../../components/AdminComponents/Modal/ModalComponent'
import uuid from 'react-uuid'
import { useDispatch } from 'react-redux'
import { addPageSpinner, removePageSpinner } from './../../../../store/spinner/actions';
import AlertService from '../../../../Services/alertService'
import {
  Alert,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Table,
  Label,
  Input
} from "reactstrap"
import ApiService from './../../../../Services/apiService'
import { ERROR_KEY, SUCCESS_KEY } from './../../../../Constants/MainKeys'
import ReactPaginate from 'react-paginate'


const UserTypes = (props) => {

  const dispatch = useDispatch()

  const namemaxLength = 120

  const [isShowModal, setIsShowModal] = useState(false)
  const [isShowModalSpinner, setIsShowModalSpinner] = useState(false)
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false)
  const [userTypeId, setUserTypeId] = useState(null)
  const [name, setName] = useState("")
  const [userTypes, setUserTypes] = useState([])

  const cancel = () => {
    setIsShowModal(false)
    setIsInvalidSubmit(false)
    setName("")
  }

  const removeUserType = (userType) => {
    AlertService.alertConfirm(
      `Are you sure you want to delete "${userType.name}" ?`, "", `yes`, 'no'
    ).then(() => {

    })
  }

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
  }

  const onSubmit = () => {
    if (!name.trim()) {
      setIsInvalidSubmit(true);
      return;
    }
    setIsShowModalSpinner(true);
    setTimeout(() => {
      setIsShowModalSpinner(false);
    }, 2000);
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    dispatch(removePageSpinner(spinnerId))
  };

  return <React.Fragment>
    <div className="page-content">
      <MetaTags>
        <title>User Types</title>
      </MetaTags>
      <Container fluid={true}>
        <Breadcrumbs title="User Types" breadcrumbItem="User Types" />
        <button
          className="btn btn-primary btn-lg  btn btn-primary mb-4"
          onClick={() => setIsShowModal(true)}
        >
          Create User Type
        </button>
        {
          isShowModal ?
            <ModalComponent
              title={`${userTypeId ? "Update User Type" : "Create User Type"}`}
              actionButtonTitle={`${userTypeId ? "Update" : "Create"}`}
              isShowModalSpinner={isShowModalSpinner}
              onSubmit={onSubmit}
              cancel={cancel}
            >
              <Row>
                <Col className='col-12'>
                  <div className="mb-3 m-2">
                    <Label htmlFor="name">Name *</Label>
                    <Input
                      type="text"
                      className={`form-control ${isInvalidSubmit && !name.trim() ? "error-border" : ""}`}
                      id="name"
                      value={name}
                      onChange={(event) => onChange(event, setName, namemaxLength)}
                    />
                  </div>
                </Col>
              </Row>
            </ModalComponent>
            : null
        }
        <Row>
          <Col xl={12}>
            {/* {
              userTypes && userTypes.length ? */}
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Table className="table table align-middle table-nowrap table-hover">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='cursor-default'>
                        <th>create_page</th>
                        <td>
                          <div className="d-flex gap-3">
                            <a className="text-success" href="#">
                              <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
                            </a>
                            <a className="text-danger" href="#">
                              <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={removeUserType}></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            {/* : <div>
                  <hr />
                  <p>You don't have any created user types</p>
                </div>
            } */}
          </Col>
          <div className="row mt-2">
            <Col className="col-12">
              <div>
                <ReactPaginate
                  nextLabel={"next"}
                  // onPageChange={this.handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={10}
                  previousLabel={"prev"}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                  forcePage={0}
                />
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}

export default withRouter(UserTypes)
