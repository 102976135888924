import axios from 'axios';
import AlertService from './alertService';
import { API_URL_KEY, IDENTITY_API_URL_KEY } from '../Constants/ApiKeys';
import { DEFAULT_LANGUAGE_KEY, ERROR_KEY, LANGUAGE_KEY, OBJECT_KEY, TOKEN_KEY } from './../Constants/MainKeys';


const api = axios.create({
  baseURL: API_URL_KEY,
  withCredentials: true,
});

const identityApi = axios.create({
  baseURL: IDENTITY_API_URL_KEY
});

api.interceptors.request.use(async config => {
  config.headers = {
    'Authorization': localStorage.getItem(TOKEN_KEY) ? localStorage.getItem(TOKEN_KEY) : "",
    'language': localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY,
  };
  return config;
});

const showError = (errorMsg) => {
  errorMsg && AlertService.alert(ERROR_KEY, errorMsg);
}

const removeAlertBlock = () => {
  var currentBlock = document.getElementById("alertBlockCloseButton");
  if (currentBlock && currentBlock.parentNode) {
    currentBlock.parentNode.removeChild(currentBlock);
  } else { return; }
}

api.interceptors.response.use(response => {
  if (response && response.data && response.data?.status === 500) {
    AlertService.alert(ERROR_KEY, "Something went wrong");
  }
  if (response && response.data && response.data.status !== 200) {
    const currentError = response.data;

    //if typeof error === string
    // if (currentError && currentError.message && currentError.message.includes("TR_") && (!currentError.errors || (currentError.errors && !currentError.errors.length))) {
    if (currentError && currentError.message && (!currentError.errors || (currentError.errors && !currentError.errors.length))) {
      return Promise.reject(currentError);
    }

    //if typeof error === Array
    if (currentError.errors && currentError.errors.length && !Object.keys(currentError.errors).length) {
      currentError.errors.forEach(err => {
        if (err.length && err[0] && err[0].key) {
          return Promise.reject(err[0].key);
        }
      });
    }

    //if typeof error === Object
    if (currentError.errors && Object.keys(currentError.errors).length) {
      return Promise.reject(currentError.errors[Object.keys(currentError.errors)[0]][0]?.key);
    }
  }

  if (response && response.data) {
    if (response.data.respmess === 'User is not Authenticated') {
      // localStorage.clear();
      window.location.href = `/`;
    }
    return !response.data.respcode ? response.data : Promise.reject({ respmess: response.data.respmess, respcode: response.data.respcode });
  }
}, error => {

  if (error && JSON.stringify(error).includes("404")) {
    return Promise.reject("Response code 404");
  }

  if (error.response && error.response.status === 401) {
    return Promise.reject("401 Unauthorized response");
  }

  if (error && JSON.stringify(error).includes("400")) {
    return Promise.reject("Request failed with status code 400");
  }

  if (error.response) {
    const currentError = error.response.data;

    //if typeof error === string
    if (currentError && currentError.message && (!currentError.errors || (currentError.errors && !currentError.errors.length))) {
      return Promise.reject(currentError);
    }

    //if typeof error === Array
    if (currentError.errors && currentError.errors.length) {
      currentError.errors.forEach(err => {
        if (err.length && err[0] && err[0].key) {
          AlertService.alert(ERROR_KEY, err[0].key);
        }
      });
    }

    //if typeof error === Object
    if (typeof currentError.errors === OBJECT_KEY && Object.keys(currentError.errors).length) {
      return Promise.reject(currentError.errors[Object.keys(currentError.errors)[0]][0]?.key);
    }
  } else {
    return Promise.reject();
  }
});

class ApiService {

  static getAllTranslations() {
    return api.get(`/translation/GetByLanguageIsoCode`);
  };

  static currentUser() {
    return api.get(`/user/current`);
  };

  static getLanguages() {
    return api.get(`/language/getall`);
  };

  static getPagesAsAdmin() {
    return api.get(`/page/GetAllAdmin`);
  };

  static getPageById(id) {
    return api.get(`/page/GetAdmin/${id}`);
  };

  static createPage(data) {
    return api.post(`/page/create`, data);
  };

  static updatePage(data) {
    return api.put(`/page/update`, data);
  };

  static deletePage(pageId) {
    return api.delete(`/page/delete/${pageId}`);
  };

  static getPageContentById(contentId, languageId) {
    return api.get(`/page/GetContent/${contentId}/${languageId}`);
  };

  static createPageContent(data) {
    return api.post(`/page/createcontent`, data);
  };

  static updatePageContent(data) {
    return api.put(`/page/updatecontent`, data);
  };

  static getPageContents(pageId, languageId) {
    return api.get(`/page/GetContentsByPage/${pageId}/${languageId}`);
  };

  static deleteContent(contentId) {
    return api.delete(`/page/deletecontent/${contentId}`);
  };

  static onChangePageContentOrder(dropContentId, dragSectionId, languageId) {
    return api.put(`/page/changeContentOrder/${dropContentId}/${dragSectionId}/${languageId}`);
  };

  static onChangePageOrder(dropPageId, dragPageId) {
    return api.put(`/page/changePageOrder/${dropPageId}/${dragPageId}`);
  };

  static forgotPassword(data) {
    return api.post(`/???`, data);
  };

  static restorePassword(data) {
    return api.post(`/???`, data);
  };

  static createLanguage(data) {
    return api.post(`/???`, data);
  };

  static getSystemTranslations() {
    return api.get(`/translation/getall`);
  };

  static updateTranslation(data) {
    return api.put(`/translation/update`, data);
  };

  static createTranslation(data) {
    return api.post(`/translation/create`, data);
  };

  static deleteTranslation(id) {
    return api.delete(`/translation/delete/${id}`);
  };

}

export default ApiService;
