export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"
export const SOCIAL_LOGIN = "SOCIAL_LOGIN"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_FAIL = "AUTH_FAIL"
export const CURRENT_USER_SUCCESS = "CURRENT_USER_SUCCESS"
export const REMOVE_LOGIN_ERROR_MESSAGE = "REMOVE_LOGIN_ERROR_MESSAGE"
