import React, { useState, useEffect } from 'react'
import { Button, Card, Form, Modal, Nav, NavDropdown, Accordion } from 'react-bootstrap'
import { Link, useLocation, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


const SidebarContent = (props) => {

  const dispatch = useDispatch();
  const { language } = useSelector(state => state.Languages);
  const { user } = useSelector(state => state.Login);
  const { pathname } = useLocation()

  const [activeAccordionKey, setActiveAccordionKey] = useState(null);

  const handleAccordionToggle = (accordionKey) => {
    setActiveAccordionKey(accordionKey === activeAccordionKey ? null : accordionKey);
  };

  const setActiveClass = (name) => {
    if (pathname.includes(name)) return "mm-active";
    else return "";
  }

  const removeBodyClasses = () => {
    document.body.classList = '';
  }

  return (
    <section className="scroll-section left-modal-container" id="leftModal">
      <div className="mb-5 border-none custom-accordion-left-menu">

        <Accordion className='left-menu mt-5 pb-3' alwaysOpen defaultActiveKey={[0]}>

          <Accordion.Item title="Dashboard">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("dashboard")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/dashboard`);
              }}
            >
              <i className='bx bxs-dashboard'></i>
              <span>Dashboard</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Pages">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("pages")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/pages`);
              }}
            >
              <i className='bx bx-book-content'></i>
              <span>Pages</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Languages">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("languages")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/languages`);
              }}
            >
              <i className='bx bx-globe'></i>
              <span>Languages</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Translations">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("translations")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/translations`);
              }}
            >
              <i className='bx bx-text'></i>
              <span>Translations</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Payment packages">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("payment-packages")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/payment-packages`);
              }}
            >
              <i className='bx bx-square-rounded'></i>
              <span>Payment packages</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Sections">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("sections")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/sections`);
              }}
            >
              <i className='bx bx-square-rounded'></i>
              <span>Sections</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="File Library">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("file-library")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/file-library`);
              }}
            >
              <i className='bx bx-file'></i>
              <span>File Library</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Roles">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("roles")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/roles`);
              }}
            >
              <i className='bx bx-square-rounded'></i>
              <span>Roles</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Payment Systems">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("payment-systems")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/payment-systems`);
              }}
            >
              <i className='bx bx-dollar' ></i>
              <span>Payment Systems</span>
            </Accordion.Header>
          </Accordion.Item>

          {/* <Accordion.Item eventKey="1">
            <Accordion.Header
              onClick={() => handleAccordionToggle("1")}
              className={`my-2 ${setActiveClass("/payments")}`}
            >
              <i className='bx bx-transfer-alt' ></i>
              <span>Payments</span>
            </Accordion.Header>
            <Accordion.Body>
              <Accordion className="nested-accordion" alwaysOpen>
                <Accordion.Item eventKey="1.1">
                  <Accordion.Header
                    className={`without-arrow ${setActiveClass("transaction-history")}`}
                    onClick={() => {
                      removeBodyClasses();
                      props.history.push(`/${language}/payments/transaction-history`)
                    }}
                  >
                    Transactions
                  </Accordion.Header>
                </Accordion.Item>

              </Accordion>
            </Accordion.Body>
          </Accordion.Item> */}

        </Accordion>

      </div>
    </section>
  )
}

export default withRouter((SidebarContent))