import React, { useCallback } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import { withTranslation } from "react-i18next"
import { ERROR_KEY } from './../../../Constants/MainKeys'
import { addPageSpinner, removePageSpinner } from './../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch } from 'react-redux'

const Dashboard = (props) => {

  const dispatch = useDispatch();

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Relamy"
          breadcrumbItem="Dashboard"
        />
        <hr/>
        <div className='py-3'>
          <b className='d-block text-center'>No data</b>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(Dashboard)