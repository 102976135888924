// -------------------------------------------------------------------------------------
// export const IDENTITY_API_URL_KEY = 'http://localhost:63002/';
// export const API_URL_KEY = 'https://06dc-87-241-138-217.ngrok.io/';
// -------------------------------------------------------------------------------------


// -------------------------------------------------------------------------------------
//export const IDENTITY_API_URL_KEY = 'http://localhost:63002/';
//export const API_URL_KEY = 'https://9908-87-241-138-217.ngrok-free.app/';
// -------------------------------------------------------------------------------------


// -------------------------------------------------------------------------------------
export const IDENTITY_API_URL_KEY = 'https://identity.relamy.com/';
export const API_URL_KEY = 'https://api.relamy.com/';
// -------------------------------------------------------------------------------------