import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumb from "../../components/Common/Breadcrumb"
import avatar from "../../assets/images/users/avatar-1.jpg"
import { addButtonSpinner, removeButtonSpinner } from "../../store/actions"
import MainService from '../../Services/mainService';
import AlertService from '../../Services/alertService';
import { ERROR_KEY, SUCCESS_KEY, VALID_IMAGE_TYPES_KEY } from '../../Constants/MainKeys'
import uuid from 'react-uuid'
import { useCallback } from 'react'
import ActionButton from '../../components/Buttons/ActionButton'
import {
  Container,
  Row,
  Card,
  CardBody,
  Media,
  Button,
} from "reactstrap"

const spinnerId = uuid();

const UserProfile = props => {
  const mainService = new MainService();
  const maxFilesSize = 3; //MB
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.Login);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmailname] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (!user) { return false; }
    setFirstname(user.firstname);
    setLastname(user.lastname);
    setEmailname(user.email);
  }, [user]);

  const handleValidSubmit = (event, values) => {
    event && event.preventDefault();
    setButtonSpinner(spinnerId);
    setTimeout(() => {
      extractButtonSpinner(spinnerId)
      AlertService.alert(SUCCESS_KEY, "Your profile has been successfully updated")
    }, 2000);
  }

  const uploadFile = event => {
    if (!event.target.files[0]) { return; }
    const file = event.target.files[0];
    if (file) {
      if (file.size >= maxFilesSize * 1024 * 1024) {
        AlertService.alert("warning", "Incorrect file size");
        return false;
      }
      mainService.readFile(file, VALID_IMAGE_TYPES_KEY).then(uploadedImage => {
        setUploadedFile(uploadedImage);
        setFile(file);
      }).catch(error => {
        error && AlertService.alert("error", "Invalid file format");
        setUploadedFile(null);
      });
    };
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Relamy" breadcrumbItem="Profile" />
          <Card>
            <CardBody>
              <Media>
                <div className="ms-3">
                  <img
                    src={uploadedFile ? uploadedFile : user && user.avatarFilePath ? user.avatarFilePath : ""}
                    alt="/"
                    className="avatar-lg rounded-circle img-thumbnail"
                  />
                </div>
                <Media body className="align-self-center ms-3">
                  <div className="text-muted">
                    <h5 className='capitalize'>{user.fullName}</h5>
                    <p className="mb-1">{user.email}</p>
                  </div>
                </Media>
              </Media>
              <div className=" mt-4">
                <Button
                  type="button"
                  color={`${!uploadedFile ? "primary" : "danger"}`}
                  className='px-4'
                  onClick={(event) => {
                    if (!uploadedFile) {
                      MainService.triggerUploadClick(event);
                    } else {
                      setUploadedFile(null);
                      setFile(null)
                    }
                  }}
                >
                  {
                    !uploadedFile ? "Upload image" : "Remove image"
                  }
                </Button>
                <input type="file" id='avatarImage' className="d-none" hidden onChange={uploadFile} />
              </div>
              <hr />
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <Row>
                  <div className='col-12 col-md-6'>
                    <div className="form-group mt-3">
                      <AvField
                        name="firstname"
                        label="First name *"
                        placeholder="First name"
                        value={firstname}
                        className="form-control"
                        type="text"
                        required
                      />
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className='col-12 col-md-6'>
                    <div className="form-group mt-3">
                      <AvField
                        name="lastname"
                        label="Last name *"
                        placeholder="Last name"
                        value={lastname}
                        className="form-control"
                        type="text"
                        required
                      />
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className='col-12 col-md-6'>
                    <div className="form-group mt-3">
                      <AvField
                        name="email"
                        label="Email *"
                        placeholder="Email"
                        value={email}
                        className="form-control"
                        type="email"
                        required
                      />
                    </div>
                  </div>
                </Row>

                <div className="mt-4 d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    name="Save"
                    className="btn btn-primary btn-block px-4"
                    spinnerId={spinnerId}
                  />
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default (UserProfile)
