import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  return (
    <Row>
      <Col xs="12">
        <div className={`page-title-box d-sm-flex align-items-center justify-content-between`}>
          {
            props.isShowGoBackButton ?
              <div className="d-flex align-items-center">
                <div className='back-arrow-block'>
                  <i className='bx bx-left-arrow-circle' onClick={props.goBack} />
                </div>
                <h4 className="mb-0 font-size-22">{props.breadcrumbItem}</h4>
              </div>
              : <h4 className="mb-0 font-size-22">{props.breadcrumbItem}</h4>
          }
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="#" className="cursor-default">{props.title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to="#" className="cursor-default">{props.breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
