import React from 'react';
import MetaTags from 'react-meta-tags'
import {
  Col,
  Row,
  Card,
  CardBody,
  Container,
  Table,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';

const Sections = (props) => {

  const language = useSelector(state => state.Languages.language)

  return <React.Fragment>
    <div className="page-content">
      <MetaTags>
        <title>Sections</title>
      </MetaTags>
      <Container fluid={true}>
        <Breadcrumbs title="Sections" breadcrumbItem="Sections" />
        <div className='my-2'>
          <div className='d-flex justify-content-end'>
            <button className="btn btn-outline-primary" type="button" onClick={() => props.history.push(`/${language}/sections/form`)}>
              Create Section
            </button>
          </div>
        </div>
        <hr />
        <Col lg={12}>
          <Card>
            <CardBody>
              <div className="table-responsive">
                <Table className="table table align-middle table-nowrap table-hover">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Sub Title</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='cursor-default'>
                      <td>Section Title 1</td>
                      <td>Section Sub Title 1</td>
                      <td>
                        <div className="d-flex gap-3">
                          <Link className="text-success" to="#">
                            <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
                          </Link>
                          <Link className="text-danger" to="#">
                            <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr className='cursor-default'>
                      <td>Section Title 2</td>
                      <td>Section Sub Title 2</td>
                      <td>
                        <div className="d-flex gap-3">
                          <Link className="text-success" to="#">
                            <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
                          </Link>
                          <Link className="text-danger" to="#">
                            <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
        <div className="row mt-2">
          <Col className="col-12">
            <div>
              <ReactPaginate
                nextLabel={"next"}
                // onPageChange={this.handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={10}
                previousLabel={"prev"}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
                forcePage={0}
              />
            </div>
          </Col>
        </div>
        <Row>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}
Sections.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Sections))