import React, { useState } from 'react';
import MetaTags from 'react-meta-tags'
import {
  Col,
  Row,
  Container,
  Label,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  CardBody,
  Card,
  CardTitle,
  Input,
  Form
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import Breadcrumbs from './../../../../components/Common/Breadcrumb'
import ApiService from './../../../../Services/apiService';
import AlertService from './../../../../Services/alertService';
import { ERROR_KEY } from './../../../../Constants/MainKeys';

const PaymentPackages = (props) => {

  const packageId = props.match.params.packageId;

  const qualityArray = [
    {
      key: 0,
      value: "144p"
    },
    {
      key: 1,
      value: "240p"
    },
    {
      key: 2,
      value: "360p"
    },
    {
      key: 3,
      value: "480p"
    },
    {
      key: 4,
      value: "720p"
    },
    {
      key: 5,
      value: "1080p HD"
    },
    {
      key: 6,
      value: "1440p HD"
    },
    {
      key: 7,
      value: "2160p 4K"
    },
  ]

  const allowSaveVideoArray = [
    {
      key: false,
      value: "No"
    },
    {
      key: true,
      value: "Yes"
    },
  ]
  const faceDetectionAnalyzerArray = [
    {
      key: false,
      value: "No"
    },
    {
      key: true,
      value: "Yes"
    },
  ]

  const nameMaxLength = 120
  const descriptionMaxLength = 2000

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [cost, setCost] = useState(0)
  const [userMaxCount, setUserMaxCount] = useState(0)
  const [storageSize, setStorageSize] = useState(0)
  const [quality, setQuality] = useState(0)
  const [allowSaveVideo, setAllowSaveVideo] = useState(false)
  const [faceDetectionAnalyzer, setFaceDetectionAnalyzer] = useState(false)
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false)
  const [isShowButtonSpinner, setIsShowButtonSpinner] = useState(false);


  const onTextChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
  }

  const onNumberChange = (event, cb) => {
    if (+event.target.value.charAt(0) === 0) {
      event.target.value = event.target.value.substring(1);
    }
    if (event.target.value === '' || (typeof +event.target.value === "number" && Number(event.target.value) > 0 && Number(event.target.value) < 9999999)) {
      cb(+event.target.value)
    }
  };

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!name.trim() || !description.trim()) {
      setIsInvalidSubmit(true)
      return;
    } else {
      setIsShowButtonSpinner(true)
      const form = {
        name: name.trim(),
        description: description.trim(),
        cost: +(Math.round(cost * 100) / 100).toFixed(2),
        allowSaveVideo,
        quality,
        faceDetectionAnalyzer,
        userMaxCount,
        storageSize,
      }
      ApiService.createPaymentPackage(form).then(() => {
        setIsShowButtonSpinner(false)
      }).catch(error => getFail(error))
    }
  }

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    setIsShowButtonSpinner(false)
  };

  return <React.Fragment>
    <div className="page-content">

      <MetaTags>
        <title>{packageId ? "Update Payment Package" : "Create Payment Package"}</title>
      </MetaTags>
      <Container fluid={true}>
        <Breadcrumbs
          title={packageId ? "Update Payment Package" : "Create Payment Package"}
          breadcrumbItem={packageId ? "Update Payment Package" : "Create Payment Package"}
          isShowGoBackButton={true}
          goBack={() => window.history.back()}
        />
        <Row>
          <Col lg={8} md={12}>
            <Card>
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <div className="mb-3">
                    <Label htmlFor="name">Name</Label>
                    <Input
                      id="name"
                      type="text"
                      value={name}
                      className={`form-control ${isInvalidSubmit && (!name || !name.trim()) ? "error-border" : ""}`}
                      onChange={(event) => onTextChange(event, setName, nameMaxLength)}
                    />
                  </div>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="cost">Cost</Label>
                        <Input
                          id="cost"
                          type="number"
                          value={cost}
                          className={`form-control`}
                          onChange={(event) => onNumberChange(event, setCost)}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="userMaxCount">User Max Count</Label>
                        <Input
                          id="userMaxCount"
                          type="number"
                          value={userMaxCount}
                          className={`form-control`}
                          onChange={(event) => onNumberChange(event, setUserMaxCount)}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="storageSize">Storage Size</Label>
                        <Input
                          id="storageSize"
                          type="number"
                          value={storageSize}
                          className={`form-control`}
                          onChange={(event) => onNumberChange(event, setStorageSize)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="name">Quality</Label>
                        <select className="form-select" onChange={(event) => setQuality(+event.target.value)}>
                          {qualityArray.map(({ value, key }, index) => <option value={key} key={index} >{value}</option>)}
                        </select>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="name">Allow video save</Label>
                        <select className="form-select" onChange={(event) => setAllowSaveVideo(JSON.parse(event.target.value))}>
                          {allowSaveVideoArray.map(({ value, key }, index) => <option value={key} key={index} >{value}</option>)}
                        </select>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="name">Face detection analyzer</Label>
                        <select className="form-select" onChange={(event) => setFaceDetectionAnalyzer(JSON.parse(event.target.value))}>
                          {faceDetectionAnalyzerArray.map(({ value, key }, index) => <option value={key} key={index} >{value}</option>)}
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="name">Description</Label>
                        <textarea
                          id="description"
                          value={description}
                          className={`form-control ${isInvalidSubmit && (!description || !description.trim()) ? "error-border" : ""}`}
                          rows={8}
                          onChange={(event) => onTextChange(event, setDescription, descriptionMaxLength)}
                        />
                      </div>
                    </Col>
                  </Row>
                  {
                    isShowButtonSpinner ?
                      <Button
                        color="light"
                        disabled={true}
                      >
                        <i className='bx bx-hourglass bx-spin font-size-16 align-middle me-2' />
                        Loading
                      </Button>
                      : <Button type="submit" color="primary">{!packageId ? "Create Payment Package" : "Update Payment Package"}</Button>
                  }
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}

export default withRouter(PaymentPackages)
