import React, { useState } from 'react';
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  UncontrolledAlert,
  Container,
  Table,
  Label,
  Input,
  Form,
  Button
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { CKEditor } from 'ckeditor4-react'
import img1 from "../../../../assets/images/companies/img-1.png"
import img2 from "../../../../assets/images/companies/img-2.png"
import img3 from "../../../../assets/images/companies/img-3.png"
import img4 from "../../../../assets/images/companies/img-4.png"
import img5 from "../../../../assets/images/companies/img-5.png"
import img6 from "../../../../assets/images/companies/img-6.png"
import ModalComponent from './../../../../components/AdminComponents/Modal/ModalComponent';


const SectionForm = (props) => {

  const imgArr = [
    {
      filePath: img1,
      url: "asdasdasd.asd",
    },
    {
      filePath: img2,
      url: "asdasdasd.asd",
    },
    {
      filePath: img3,
      url: "asdasdasd.asd",
    },
    {
      filePath: img4,
      url: "asdasdasd.asd",
    },
    {
      filePath: img5,
      url: "asdasdasd.asd",
    },
    {
      filePath: img6,
      url: "asdasdasd.asd",
    },
  ]

  const sectionId = props.match.params.sectionId;
  const titleMaxLength = 120;
  const subTitleMaxLength = 240;
  const descriptionMaxLength = 2000;
  const pageContentMaxLength = 2000000;
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [pageContentMaxLengthError, setPageContentMaxLengthError] = useState(false);
  const [isShowButtonSpinner, setIsShowButtonSpinner] = useState(false);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [pageContent, setPageContent] = useState("");
  const [imagesArray, setImagesArray] = useState(imgArr);
  const [isShowModal, setIsShowModal] = useState(false)


  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
  }

  const onEditorChange = (event) => {
    const newRichText = event.editor.getData();
    if (newRichText.length > pageContentMaxLength) {
      setPageContentMaxLengthError(true);
    } else {
      setPageContentMaxLengthError(false);
      setPageContent(newRichText);
    }
  }

  const getImages = () => {
    //request
    //after
    setIsShowModal(true)

  }

  const closeImagesModal = () => {
    setIsShowModal(false)
  }

  const cancel = () => {
    setTitle("");
    setSubTitle("");
    setDescription("");
    setPageContent("");
  }

  const onSubmit = (event) => {
    event.preventDefault();
    setIsShowButtonSpinner(true);
    console.log(pageContent);
    setTimeout(() => {
      setIsShowButtonSpinner(false);
    }, 2000);
  }

  const copyInvitationLink = (index, link) => {
    if (typeof index !== "number" || !link) { return; }
    navigator.clipboard.writeText(link);
    const imagesArr = [...imagesArray];
    const currentImageArray = { ...imagesArr[index] };
    currentImageArray.isShowCopiedMessage = true;
    imagesArr[index] = currentImageArray;
    setImagesArray([...imagesArr])
    setTimeout(() => {
      currentImageArray.isShowCopiedMessage = false;
      imagesArr[index] = currentImageArray;
      setImagesArray([...imagesArr])
    }, 500);
  }



  return <React.Fragment>
    <div className="page-content">
      <MetaTags>
        <title>{!sectionId ? props.t("Create Section") : props.t("Update Section")}</title>
      </MetaTags>
      <Container fluid={true}>
        <Breadcrumbs
          title={!sectionId ? props.t("Create Section") : props.t("Update Section")}
          breadcrumbItem={!sectionId ? props.t("Create Section") : props.t("Update Section")}
          isShowGoBackButton={true}
          goBack={() => window.history.back()}
        />
        <Button
          type="button"
          color="success"
          className='mb-3 mt-1'
          onClick={getImages}
        >
          {props.t("Show Images")}
        </Button>
        {
          isShowModal ?
            <ModalComponent
              title="Images"
              cancel={closeImagesModal}
              isLarge={true}
            >
              <div className="mb-3 all-images">
                {
                  imagesArray.map((image, index) => <div key={index} className='current-image' style={{ backgroundImage: `url(${image.filePath})` }}>
                    {
                      image.isShowCopiedMessage ?
                        <span>Copied</span> :
                        <i className='bx bx-copy' title='Copy image url' onClick={() => copyInvitationLink(index, image.url)} />
                    }
                  </div>)
                }
              </div>
            </ModalComponent>
            : null
        }

        <Row>
          <Col lg={8} md={12}>
            <Card>
              <CardBody>
                <Form className="repeater" onSubmit={onSubmit}>
                  <Col>
                    <Label htmlFor="title">{props.t("Title")}</Label>
                    <Input
                      id="title"
                      type="text"
                      value={title}
                      className={`form-control ${isInvalidSubmit && (!title || !title.trim()) ? "error-border" : ""}`}
                      onChange={(event) => onChange(event, setTitle, titleMaxLength)}
                    />
                  </Col>
                  <Col className='mt-4'>
                    <Label htmlFor="subTitle">{props.t("Sub Title")}</Label>
                    <Input
                      id="subTitle"
                      type="text"
                      value={subTitle}
                      className={`form-control ${isInvalidSubmit && (!subTitle || !subTitle.trim()) ? "error-border" : ""}`}
                      onChange={(event) => onChange(event, setSubTitle, subTitleMaxLength)}
                    />
                  </Col>
                  <Col className='mt-4'>
                    <Label htmlFor="description">{props.t("Description")}</Label>
                    <textarea
                      id="description"
                      value={description}
                      className="form-control"
                      rows={6}
                      onChange={(event) => onChange(event, setDescription, descriptionMaxLength)}
                    />
                  </Col>
                  <Col className='mt-4'>
                    <Label>{props.t("Page Content")}</Label>
                    <div className={pageContentMaxLengthError ? "error-border" : ""}>
                      <Input
                        type='textarea'
                        value={pageContent}
                        onChange={() => onChange(pageContent, setPageContent)}
                        rows="5"
                      />
                      {/* <CKEditor
                        initData={pageContent}
                        onChange={onEditorChange}
                        config={
                          { name: 'document', groups: ['mode', 'document', 'doctools'] },
                          { name: 'clipboard', groups: ['clipboard', 'undo'] },
                          { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
                          { name: 'forms', groups: ['forms'] },
                          '/',
                          { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                          { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
                          { name: 'links', groups: ['links'] },
                          { name: 'insert', groups: ['insert'] },
                          '/',
                          { name: 'styles', groups: ['styles'] },
                          { name: 'colors', groups: ['colors'] },
                          { name: 'tools', groups: ['tools'] },
                          { name: 'others', groups: ['others'] },
                          { name: 'about', groups: ['about'] }
                        }
                      /> */}
                    </div>
                    {
                      pageContentMaxLengthError ? <small className="red-color mt-3">{props.t("The page content is too long")}</small> : null
                    }
                  </Col>
                  <div className='my-4'>
                    {
                      isShowButtonSpinner ?
                        <Button
                          color="light"
                          disabled={true}
                        >
                          <i className='bx bx-hourglass bx-spin font-size-16 align-middle me-2' />
                          {props.t("Loading")}
                        </Button>
                        : <Button type="submit" color="primary">{!sectionId ? props.t("Create Section") : props.t("Update Section")}</Button>
                    }
                  </div>

                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}

SectionForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SectionForm))
