import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import { logoutUser } from "../../store/actions"

const Logout = props => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutUser(props.history))
  })

  return <></>
}
export default Logout
