import React, { useCallback, useState, useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { ERROR_KEY, SUCCESS_KEY } from '../../../Constants/MainKeys'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  Input,
  Label,
  Col
} from "reactstrap"
import uuid from 'react-uuid'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption';
import ActionButton from '../../../components/Buttons/ActionButton'
import ApiService from '../../../Services/apiService'


const buttonSpinnerId = uuid();

const PageContentForm = (props) => {

  const dispatch = useDispatch();
  const { pageId, contentId, languageId } = props.match.params;
  const [pages, setPages] = useState([]);
  const { language, languages } = useSelector(state => state.Languages);

  const [values, setValues] = useState({
    languageId: null,
    pageId: null,
    title: "",
    subtitle: "",
    shortContent: "",
    fullContent: "",
    showInSlider: false,
  })

  useEffect(() => {
    getPages();

  }, [])

  useEffect(() => {
    contentId && getPageContentById()
  }, [contentId, languageId])

  const onChange = (event, name, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [name]: event.target.value,
    }));
  }

  const getPages = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getPagesAsAdmin().then(response => {
      if (response && response.data) {
        setPages(response.data);
      } else {
        setPages([]);
      }
      setValues((values) => ({
        ...values,
        pageId: +pageId,
      }));
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const getPageContentById = () => {
    if (!pageId) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getPageContentById(contentId, languageId).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        setValues((values) => ({
          ...values,
          languageId: data.languageId,
          pageId: data.pageId,
          title: data.title,
          subtitle: data.subtitle,
          shortContent: data.shortContent,
          fullContent: data.fullContent,
          showInSlider: data.showInSlider,
        }));
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const onCheckboxChange = (event, name) => {
    setValues((values) => ({
      ...values,
      [name]: event.target.checked,
    }));
  }

  const onSelectOptionChange = (item, name) => {
    if (!item) { return false; }
    setValues((values) => ({
      ...values,
      [name]: item.value ? item.value : item,
    }));
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    setButtonSpinner(buttonSpinnerId);
    const form = { ...values }
    if (contentId) { form.id = contentId }
    (contentId ? ApiService.updatePageContent(form) : ApiService.createPageContent(form)).then(response => {
      extractButtonSpinner(buttonSpinnerId);
      if (contentId) {
        AlertService.alert(SUCCESS_KEY, "Page content updated successfully");
      } else {
        AlertService.alert(SUCCESS_KEY, "Page content created successfully");
      }
      window.history.back()
    }).catch(error => getFail(error, buttonSpinnerId));
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);


  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Page content form"
          breadcrumbItem="Page content form"
          isShowGoBackButton={true}
          goBack={() => window.history.back()}
        />
        <>
          <form className='row mb-3' onSubmit={onSubmit}>
            <Col md={6}>
              <div className='mb-2'>
                <Label className='text-muted text-truncate mb-0'>Title</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Title"
                  value={values.title}
                  onChange={(event) => onChange(event, "title", 200)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className='mb-2'>
                <Label className='text-muted text-truncate mb-0'>Subtitle</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Subtitle"
                  value={values.subtitle}
                  onChange={(event) => onChange(event, "subtitle", 200)}
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="mt-2">
                <Label className='text-muted text-truncate mb-0'>Parent page</Label>
                <ReactSelectOption
                  value={values.pageId}
                  isSearchable={false}
                  isDisabled={pageId ? true : false}
                  selectedValue={(() => {
                    const selectedItem = { ...pages.find(data => +data.id === +values.pageId) };
                    if (selectedItem) {
                      selectedItem.label = selectedItem.name;
                      selectedItem.value = selectedItem.id;
                    }
                    return selectedItem;
                  })()}
                  items={pages.map(data => ({ ...data, label: data.name, value: data.id }))}
                  onChange={(item) => onSelectOptionChange(item, "pageId")}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-2">
                <Label className='text-muted text-truncate mb-0'>Languages</Label>
                <ReactSelectOption
                  value={values.languageId}
                  isSearchable={false}
                  selectedValue={(() => {
                    const selectedItem = { ...languages.find(data => +data.id === +values.languageId) };
                    if (selectedItem) {
                      selectedItem.label = selectedItem.name;
                      selectedItem.value = selectedItem.id;
                    }
                    return selectedItem;
                  })()}
                  items={languages.map(data => ({ ...data, label: data.name, value: data.id }))}
                  onChange={(item) => onSelectOptionChange(item, "languageId")}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className='mb-2 mt-2 mt-md-0 h-100 d-md-flex align-items-end'>
                <div className="form-check mb-3">
                  <Input
                    id="showInSlider"
                    name="showInSlider"
                    className="form-check-input"
                    type="checkbox"
                    checked={values.showInSlider}
                    onChange={(event) => onCheckboxChange(event, "showInSlider")}
                  />
                  <Label
                    className="form-check-label cursor-pointer"
                    htmlFor="showInSlider"
                  >
                    Show in slider
                  </Label>
                </div>
              </div>
            </Col>
            <div className='my-2'>
              <Label className='text-muted text-truncate mb-0'>Short content</Label>
              <Input
                type="textarea"
                className="form-control"
                placeholder="Short content"
                value={values.shortContent}
                rows="8"
                onChange={(event) => onChange(event, "shortContent")}
              />
            </div>
            <div className='my-2'>
              <Label className='text-muted text-truncate mb-0'>Full content</Label>
              <Input
                type="textarea"
                className="form-control"
                placeholder="Full content"
                value={values.fullContent}
                rows="8"
                onChange={(event) => onChange(event, "fullContent")}
              />
            </div>

            <Col>
              <ActionButton
                type="submit"
                name={`${contentId ? "Update" : "Create"}`}
                className="shadow-sm ms-auto px-4 mt-3 btn btn-primary"
                spinnerId={buttonSpinnerId}
              />
            </Col>
          </form>
        </>
      </div>
    </div>
  )
}

export default withRouter(PageContentForm)