import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import "./Css/main.css"
import { Provider } from "react-redux"
import "react-confirm-alert/src/react-confirm-alert.css"
import 'react-drawer/lib/react-drawer.css';

import store from "./store"

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
