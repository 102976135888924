import { GET_TRANSLATIONS_SUCCESS, CHANGE_LNAGUAGE } from './actionTypes';

let initialState = {
    translations: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TRANSLATIONS_SUCCESS:
            return {
                ...state,
                translations: action.payload
            };
        case CHANGE_LNAGUAGE:
            return { ...state }
        default:
            break;
    }
    return state;
};

export default reducer;

