import React, { useState } from 'react'
import PropTypes from "prop-types"
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  Button, Modal, ModalFooter,
  ModalHeader, ModalBody
} from "reactstrap"
import { withRouter } from 'react-router-dom'
//i18n
import { withTranslation } from "react-i18next"

function ModalComponent(props) {

  return (
    <div>
      <Modal isOpen={true}
        toggle={props.cancel}
        size={props.isLarge ? "lg" : ""}
      >
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>
          {props.children}
        </ModalBody>
        <ModalFooter>
          <Button onClick={props.cancel}>
            {props.t("Cancel")}
          </Button>
          {' '}
          {
            !props.isShowModalSpinner ?
              props.actionButtonTitle ?
                <Button
                  color="primary"
                  onClick={() => {
                    if (props.onSubmit) {
                      props.onSubmit()
                    }
                  }}
                >
                  {props.actionButtonTitle}
                </Button>
                : null
              :
              <Button
                color="light"
                disabled={true}
              >
                <i className='bx bx-hourglass bx-spin font-size-16 align-middle me-2' />
                {props.t("Loading")}
              </Button>
          }
          
        </ModalFooter>
      </Modal>
    </div >
  );
}
ModalComponent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ModalComponent))
