import { GET_TRANSLATIONS_REQUEST, CHANGE_LNAGUAGE } from "./actionTypes"

export function getTranslations() {
  return {
    type: GET_TRANSLATIONS_REQUEST,
    payload: {},
  };
};

export function onLanguageChange(isoCode) {
  return {
    type: CHANGE_LNAGUAGE,
    payload: isoCode,
  };
};


