import React, { useState } from 'react';
import MetaTags from 'react-meta-tags'
import {
  Col,
  Row,
  Container,
  Label,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  CardBody,
  Card,
  CardTitle,
  Input,
  Form
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import Breadcrumbs from './../../../../components/Common/Breadcrumb'
import Dropzone from "react-dropzone"
import AlertService from './../../../../Services/alertService';
import { ERROR_KEY, NUMBER_KEY, VALID_IMAGE_TYPES_KEY, SUCCESS_KEY } from './../../../../Constants/MainKeys';
import MainService from './../../../../Services/mainService';
import ApiService from './../../../../Services/apiService';


const PaymentSystemForm = (props) => {

  const mainService = new MainService();
  const systemId = props.match.params.systemId;

  const nameMaxLength = 120

  const [name, setName] = useState("")
  const [fee, setFee] = useState("")
  const [minDeposit, setMinDeposit] = useState("")
  const [maxDeposit, setMaxDeposit] = useState("")
  const [psLogo, setPsLogo] = useState(null)

  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false)
  const [isShowButtonSpinner, setIsShowButtonSpinner] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null)


  const onTextChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
  }

  const onNumberChange = (event, cb) => {
    if (+event.target.value.charAt(0) === 0) {
      event.target.value = event.target.value.substring(1);
    }
    if (event.target.value === '' || (typeof +event.target.value === "number" && Number(event.target.value) > 0 && Number(event.target.value) < 9999999)) {
      cb(+event.target.value)
    }
  };

  const uploadImage = event => {
    if (!event || !event.target.files[0]) { return; }
    const file = event.target.files[0];
    setPsLogo(file)
    mainService.readFile(file, VALID_IMAGE_TYPES_KEY).then(image => {
      if (image) {
        setPsLogo(file)
        setUploadedImage(image)
      }
    }).catch(error => error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), "invalid image type"));
  }

  const removeFile = () => {
    AlertService.alertConfirm(
      `Are you sure you want to delete this file?`, "", `yes`, 'no'
    ).then(() => {
      setUploadedImage(null)
    })
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!name.trim() || (!fee && typeof fee !== NUMBER_KEY) || !minDeposit || !maxDeposit) {
      setIsInvalidSubmit(true)
      return;
    } else {
      setIsShowButtonSpinner(true)
      const form = {
        Fee: fee,
        MaxDeposit: maxDeposit,
        MinDeposit: minDeposit,
        PSLogo: psLogo,
        Name: name.trim(),
      }
      const formData = new FormData();
      for (const i in form) {
        formData.append(`${i}`, form[i])
      }
      ApiService.createPaymentSystem(formData).then(() => {
        setIsShowButtonSpinner(false)
        AlertService.alert(SUCCESS_KEY, "Payment system crreated successfully")
      }).catch(error => getFail(error))
    }
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    setIsShowButtonSpinner(false)
  };

  return <React.Fragment>
    <div className="page-content">

      <MetaTags>
        <title>{systemId ? "Update Payment System" : "Create Payment System"}</title>
      </MetaTags>
      <Container fluid={true}>
        <Breadcrumbs
          title={systemId ? "Update Payment System" : "Create Payment System"}
          breadcrumbItem={systemId ? "Update Payment System" : "Create Payment System"}
          isShowGoBackButton={true}
          goBack={() => props.history.push(`/payment-systems`)}
        />
        <Row>
          <Col lg={8} md={12}>
            <Card>
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <div className="mb-3">
                    <Label htmlFor="name">Name</Label>
                    <Input
                      id="name"
                      type="text"
                      value={name}
                      className={`form-control ${isInvalidSubmit && (!name || !name.trim()) ? "error-border" : ""}`}
                      onChange={(event) => {
                        onTextChange(event, setName, nameMaxLength)
                      }}
                    />
                  </div>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="fee">Fee</Label>
                        <Input
                          id="fee"
                          type="number"
                          value={fee}
                          className={`form-control ${isInvalidSubmit && (!fee && typeof fee !== NUMBER_KEY) ? "error-border" : ""}`}
                          onChange={(event) => onNumberChange(event, setFee)}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="minDeposit">Min Deposit</Label>
                        <Input
                          id="minDeposit"
                          type="number"
                          value={minDeposit}
                          className={`form-control ${isInvalidSubmit && !minDeposit ? "error-border" : ""}`}
                          onChange={(event) => onNumberChange(event, setMinDeposit)}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="maxDeposit">Max Deposit</Label>
                        <Input
                          id="maxDeposit"
                          type="number"
                          value={maxDeposit}
                          className={`form-control ${isInvalidSubmit && !maxDeposit ? "error-border" : ""}`}
                          onChange={(event) => onNumberChange(event, setMaxDeposit)}
                        />
                      </div>
                    </Col>
                    <Col className='col-12'>
                      <div className="m-2">
                        <Label htmlFor="formrow-firstname-Input ">Flag *</Label>
                        {
                          uploadedImage ?
                            <div className="dropzone-previews mb-3 upload-avatar-container" id="file-previews">
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              >
                                <div className="p-2">
                                  <Row className="align-items-center justify-content-between">
                                    <Col className="col-auto">
                                      <div className='upload-avatar-block'>
                                        <img
                                          data-dz-thumbnail=""
                                          className="avatar-sm rounded bg-light"
                                          src={uploadedImage}
                                        />
                                        <span className='remove-file-icon d-flex justify-content-end remove-avatar-file'>
                                          <i className="bx bx-trash label-icon red-color" onClick={removeFile} />
                                        </span>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </div>
                            : <input type="file" onChange={uploadImage} />
                        }
                      </div>
                    </Col>
                  </Row>
                  {
                    isShowButtonSpinner ?
                      <Button
                        color="light"
                        disabled={true}
                      >
                        <i className='bx bx-hourglass bx-spin font-size-16 align-middle me-2' />
                        Loading
                      </Button>
                      : <Button type="submit" color="primary" className='mt-4'>{!systemId ? "Create Payment System" : "Update Payment System"}</Button>
                  }
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}

export default withRouter(PaymentSystemForm)
