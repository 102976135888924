import MetaTags from 'react-meta-tags';
import React from "react"
import { Row, Col, CardBody, Card, Container } from "reactstrap"
import { connect, useSelector } from "react-redux"
import { withRouter, Link } from "react-router-dom"
// import { GoogleLogin } from "react-google-login"
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { loginUser, apiError, socialLogin, removeLoginErrorMessage } from "../../store/actions"
import profile from "./../../assets/images/profile-img.png"
import logo from "./../../assets/images/logo.svg"
import { facebook, google } from "../../config"
import uuid from 'react-uuid';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addButtonSpinner, removeButtonSpinner } from './../../store/spinner/actions';
import ActionButton from './../../components/Buttons/ActionButton';

const spinnerId = uuid();
const Login = props => {

  const dispatch = useDispatch();
  const usernameMaxLength = 120;
  const passwordMaxLength = 100;
  const { language } = useSelector(state => state.Languages);
  const loginErrorMessage = useSelector(state => state.Login.loginErrorMessage);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
    dispatch(removeLoginErrorMessage());
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (!username.trim() || !password.trim()) {
      setIsInvalidSubmit(true);
      return false;
    } else {
      dispatch(addButtonSpinner(spinnerId));
      const data = {
        username: username.trim(),
        password: password.trim()
      }
      dispatch(loginUser(data, spinnerId, props.history));
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Relamy</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Relamy.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <form
                      className="form-horizontal"
                      onSubmit={onSubmit}
                    >

                      <div className="mb-3">
                        <label htmlFor='email'>Email</label>
                        <input
                          id='email'
                          name="email"
                          className={`form-control ${isInvalidSubmit && !username.trim() ? "error-border" : ""}`}
                          placeholder="Enter email"
                          type="email"
                          required
                          value={username}
                          onChange={event => onChange(event, setUserName, usernameMaxLength)}
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor='password'>Password</label>
                        <input
                          id='password'
                          name="password"
                          className={`form-control ${isInvalidSubmit && !password.trim() ? "error-border" : ""}`}
                          placeholder="Enter password"
                          type="password"
                          required
                          value={password}
                          onChange={event => onChange(event, setPassword, passwordMaxLength)}
                        />
                      </div>

                      {
                        loginErrorMessage === "invalid_username_or_password" ?
                          <span className='text-danger d-block mb-3'>Invalid username or password</span>
                          : null
                      }

                      <div className="form-check">
                        <input
                          id="rememberMe"
                          type="checkbox"
                          className="form-check-input"
                          checked={rememberMe}
                          onChange={event => setRememberMe(event.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="rememberMe"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <ActionButton
                          type="submit"
                          name="Log In"
                          className="btn btn-primary btn-block"
                          spinnerId={spinnerId}
                        />
                      </div>

                      <div className="mt-4 text-center">
                        <Link to={`/${language}/forgot-password`} className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Relamy.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

const mapDispatchToProps = {
  loginUser,
  apiError,
  socialLogin
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Login)
)