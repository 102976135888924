

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import uuid from 'react-uuid'
// import { Tabs } from 'react-bootstrap'
// import { Tab } from 'bootstrap'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from './../../../store/spinner/actions'
import { useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Row, Col, Button, Card, CardBody, Label, Input } from 'reactstrap'
import ReactSelectOption from "./../../../components/SelectOptions/ReactSelectOption"
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Table } from "react-bootstrap"
import { useDebounce } from 'use-debounce'
import ModalComponent from '../../../components/AdminComponents/Modal/ModalComponent'
import TranslationService from './../../../Services/translationService';
import ApiService from './../../../Services/apiService';
import AlertService from '../../../Services/alertService';


const Translations = (props) => {

  const dispatch = useDispatch();
  const pageSize = 10;
  const { translations } = useSelector(state => state.Translations);
  const { languages } = useSelector(state => state.Languages);
  const [translationService, setTranslationService] = useState(null);
  const [systemTranslations, setSystemTranslations] = useState([]);
  const [selectedLanguageId, setSelectedLanguageId] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [debounceSearchText] = useDebounce(searchText, 500);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModalSpinner, setIsShowModalSpinner] = useState(false);
  const [currentTranslation, setCurrentTranslation] = useState(null);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

  const [values, setValues] = useState({
    languageId: null,
    shortKey: "TR_",
    translation1: "",
  })

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (languages && languages.length) {
      setSelectedLanguageId(languages[0].id);
      setValues(() => ({
        ...values,
        languageId: languages[0].id,
      }))
    }
  }, [languages])

  useEffect(() => {
    getSystemTranslations(1, true);
  }, [debounceSearchText, selectedLanguageId]);

  const getSystemTranslations = (pageNumber, isShowSpinner) => {
    if (!selectedLanguageId) { return false; }
    const spinnerId = uuid();
    if (isShowSpinner) setPageSpinner(spinnerId);
    ApiService.getSystemTranslations(pageNumber, pageSize, selectedLanguageId, debounceSearchText).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data && values.languageId && response.data.find(item => item.id === values.languageId)) {
        setSystemTranslations(response.data.find(item => item.id === values.languageId));
      }
      if (isShowSpinner) extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId));
  }

  const handlePageClick = (event) => {
    setActivePageNumber(event.selected);
    getSystemTranslations(event.selected + 1);
  }

  const onChange = (event, field, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [field]: field === "shortKey" ? event.target.value.toUpperCase() : event.target.value,
    }));
    setIsInvalidSubmit(false);
  }

  const onSelectOptionChange = (item) => {
    if (!item) { return false; }
    setValues((values) => ({
      ...values,
      languageId: item.id
    }));
    setIsInvalidSubmit(false);
  }

  const deleteTranslations = (translationId) => {
    if (!translationId) { return false; }
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setPageSpinner(spinnerId)
      ApiService.deleteTranslation(translationId).then(response => {
        const systemTranslationsCopy = { ...systemTranslations };
        systemTranslationsCopy.translations = systemTranslationsCopy.translations.filter(item => item.id !== translationId);
        setSystemTranslations(systemTranslationsCopy);
        AlertService.alert("success", "Data saved");
        extractPageSpinner(spinnerId)
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!values.shortKey.trim().length || !values.translation1.trim().length || !values.languageId) {
      setIsInvalidSubmit(true);
      return false;
    }
    if (currentTranslation) {
      values.id = currentTranslation.id;
    }
    setIsShowModalSpinner(true);
    (currentTranslation ? ApiService.updateTranslation(values) : ApiService.createTranslation(values)).then(response => {
      getSystemTranslations(1, true);
      AlertService.alert("success", "Data saved");
      cancel();
    }).catch(error => getFail(error))
  }

  const cancel = () => {
    setCurrentTranslation(null);
    setIsShowModal(false);
    setIsShowModalSpinner(false);
    setValues({
      languageId: selectedLanguageId ? selectedLanguageId : null,
      shortKey: "",
      translation1: "",
    })
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || "error"), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
    cancel();
  }

  return translationService && languages ?

    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Translations"
          breadcrumbItem="Translations"
        />
        <hr className='mt-0' />
        <Row className='mb-3'>
          <Col sm={12} className='d-flex justify-content-end'>
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={() => setIsShowModal(true)}
            >
              Create a translation
            </Button>
          </Col>
        </Row>

        <Card>
          <CardBody>

            {/* <div className="mb-3 position-relative">
              <input
                className="form-control"
                style={{ paddingLeft: "40px" }}
                type="search"
                placeholder='Search'
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
              />
              <i
                style={{ left: "10px", position:"absolute", top:"10px" }}
                className='bx bx-search-alt-2 font-size-24 absolute-center-horizontal opacity-50'
              />
            </div> */}

            {
              languages && languages.length && systemTranslations && systemTranslations.translations && systemTranslations.translations.length ?
                <div className="row">
                  <div className='col-12'>
                    <div className='tab-title-group tab-content'>
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={selectedLanguageId}
                        onSelect={key => {
                          if (key !== selectedLanguageId) {
                            setSelectedLanguageId(key);
                            setValues(() => ({
                              ...values,
                              languageId: +key,
                            }))
                          }
                        }}
                        className="mb-3"
                      >
                        {
                          languages.map((item, index) => {
                            return <Tab key={index} eventKey={item.id} title={item.name}>
                              <Table responsive className="table mb-0 table-bordered">
                                <thead>
                                  <tr>
                                    <th>Key</th>
                                    <th>Text</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    systemTranslations.translations.map((item, index) => {
                                      return <tr key={index} className="cursor-default">
                                        <td><div className="mindalay-admin-table-td-item">{item.shortKey}</div></td>
                                        <td><div className="mindalay-admin-table-td-item" style={{ maxWidth: "700px" }}>{item.translation1}</div></td>
                                        <td className='d-flex border-none'>
                                          <Button
                                            color="success"
                                            className="m-1 btn-sm d-flex justify-content-center align-items-center"
                                            title='Edit'
                                            outline
                                            onClick={() => {
                                              setCurrentTranslation(item);
                                              setValues(() => ({
                                                ...values,
                                                shortKey: item.shortKey,
                                                translation1: item.translation1,
                                              }))
                                            }}
                                          >
                                            <i className='mdi mdi-pencil font-size-18'></i>
                                          </Button>
                                          <Button
                                            color="danger"
                                            className="m-1 btn-sm d-flex justify-content-center align-items-center"
                                            title='Delete'
                                            outline
                                            onClick={() => deleteTranslations(item.id)}
                                          >
                                            <i className='mdi mdi-delete font-size-18'></i>
                                          </Button>
                                        </td>
                                      </tr>
                                    })
                                  }
                                </tbody>
                              </Table>
                            </Tab>
                          })
                        }
                      </Tabs>
                    </div>
                  </div>
                </div>
                : <div className='py-3'>
                  <b className='d-block text-center'>No data</b>
                </div>
            }
            {
              pagination && pagination.TotalPages > 1 && systemTranslations && systemTranslations.translations && systemTranslations.translations.length && !searchText.trim() ?
                <div className=' mt-4 d-flex justify-content-end w-100'>
                  <ReactPaginate
                    nextLabel="Next"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pagination.TotalPages}
                    previousLabel="Previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination flex-wrap"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                    forcePage={activePageNumber}
                  />
                </div>
                : null
            }
          </CardBody>
        </Card>
      </div>



      {
        currentTranslation || isShowModal ?
          <ModalComponent
            title={currentTranslation ? currentTranslation.shortKey : "Create a translation"}
            isLarge={true}
            isShowModalSpinner={isShowModalSpinner}
            actionButtonTitle={currentTranslation ? "Update" : "Create"}
            cancel={cancel}
            onSubmit={onSubmit}
          >
            <form>
              {
                languages && languages.length ?
                  <div className="mb-3">
                    <Label htmlFor='translationKey' className='font-size-14 mb-1 cursor-pointer fw-normal'>Choose the language*</Label>
                    <ReactSelectOption
                      value={values.languageId}
                      className={`currency-select rounded ${isInvalidSubmit && !values.languageId ? "error-border" : ""}`}
                      isInvalidField={isInvalidSubmit && !values.languageId}
                      selectedValue={(() => {
                        const selectedItem = { ...languages.find(data => data.id === values.languageId) };
                        if (Object.keys(selectedItem).length) {
                          selectedItem.value = selectedItem.id;
                          selectedItem.label = selectedItem.name;
                          return selectedItem;
                        } else {
                          return { label: "Ex. English", value: "" }
                        }
                      })()}
                      items={languages.map(data => ({ ...data, label: data.name, value: data.id }))}
                      onChange={item => onSelectOptionChange(item)}
                    />
                  </div>
                  : null
              }
              <div className="mb-3">
                <Label htmlFor='translationKey' className='font-size-14 mb-1 cursor-pointer fw-normal' >Short key*</Label>
                <Input
                  id="translationKey"
                  type="text"
                  className={`form-control ${isInvalidSubmit && !values.shortKey.trim().length ? "error-border" : ""}`}
                  placeholder="Ex. TR_EXAMPLE"
                  value={values.shortKey}
                  disabled={currentTranslation}
                  onChange={(event) => {
                    if (currentTranslation) {
                      return false;
                    }
                    onChange(event, "shortKey")
                  }}
                />
              </div>
              <div className="mb-3">
                <Label htmlFor='translationValue' className='font-size-14 mb-1 cursor-pointer fw-normal' >Value*</Label>
                <textarea
                  id="translationValue"
                  rows="3"
                  className={`form-control resize-none ${isInvalidSubmit && !values.translation1.trim().length ? "error-border" : ""}`}
                  placeholder="Ex. Example"
                  value={values.translation1}
                  onChange={(event) => onChange(event, "translation1")}
                />
              </div>
            </form>
          </ModalComponent>
          : null
      }
    </div>
    : null
}

export default Translations;
