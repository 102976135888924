import React, { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import { Link, withRouter } from "react-router-dom"
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import PropTypes from "prop-types"
import uuid from 'react-uuid'
import { useDispatch, useSelector } from 'react-redux'
import { addPageSpinner, removePageSpinner } from './../../../../store/spinner/actions';
import AlertService from '../../../../Services/alertService'
import {
  Alert,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Table,
  Label,
  Input
} from "reactstrap"
import ApiService from './../../../../Services/apiService'
import { ERROR_KEY, SUCCESS_KEY, VALID_IMAGE_TYPES_KEY } from './../../../../Constants/MainKeys'
import Dropzone from "react-dropzone"
import ReactPaginate from 'react-paginate'
import TranslationService from './../../../../Services/translationService';
import { onLanguageChange } from './../../../../store/translations/actions'
import MainService from './../../../../Services/mainService'
import ModalComponent from './../../../../components/AdminComponents/Modal/ModalComponent';


const Languages = (props) => {

  const mainService = new MainService()

  const dispatch = useDispatch()

  const allLanguages = useSelector(state => state.Languages.languages);
  const selectedLanguage = useSelector(state => state.Languages.language)
  const translations = useSelector(state => state.Translations.translations)

  const languagemaxLength = 120
  const isoCodeMaxLength = 10
  const isoCode2Maxlength = 10

  const [isShowModal, setIsShowModal] = useState(false)
  const [isShowModalSpinner, setIsShowModalSpinner] = useState(false)
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false)
  const [languageId, setLanguageId] = useState(null)
  const [languages, setLanguages] = useState([])

  const [translationService, setTranslationService] = useState(null)

  const [language, setLanguage] = useState("")
  const [isoCode, setIsoCode] = useState("")
  const [isoCode2, setIsoCode2] = useState("")

  const [flagIamge, setFlagImage] = useState(null)
  const [uploadedImage, setUploadedImage] = useState(null)

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations, selectedLanguage]);

  const onChangeLanguage = (isocode) => {
    dispatch(onLanguageChange(isocode))
  }

  const cancel = () => {
    setIsShowModal(false)
    setIsInvalidSubmit(false)
    setLanguage("")
    setIsoCode("")
    setIsoCode2("")
    setLanguageId(null)
    setUploadedImage(null)
    setFlagImage(null)
  }

  useEffect(() => {
    setLanguages(allLanguages)
  }, [allLanguages])

  const uploadImage = event => {
    if (!event || !event.target.files[0]) { return; }
    const file = event.target.files[0];
    setFlagImage(file)
    mainService.readFile(file, VALID_IMAGE_TYPES_KEY).then(image => {
      if (image) {
        setFlagImage(file)
        setUploadedImage(image)
      }
    }).catch(error => error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), "invalid image type"));
  }

  const getLanguageById = (id) => {
    if (!id) { return; }
    const spinnerId = uuid();
    setLanguageId(id);
    dispatch(addPageSpinner(spinnerId))
    ApiService.getLanguageById(id).then(response => {
      if (response.data) {
        const data = { ...response.data }
        setLanguage(data.name)
        setIsoCode(data.isocode)
        setIsoCode2(data.isocode2)
        setIsShowModal(true)
      }
      dispatch(removePageSpinner(spinnerId))
    }).catch(error => getFail(error, spinnerId))
  }

  const removeLanguage = (language) => {
    AlertService.alertConfirm(
      `Are you sure you want to delete "${language.name}" ?`, "", `yes`, 'no'
    ).then(() => {
      const updatedLanguages = languages.filter(lang => lang.languageId !== language.languageId);
      setLanguages(updatedLanguages)
      AlertService.alert(SUCCESS_KEY, "Language successfully deleted")
      //todo request
    })
  }

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
  }

  const removeFile = () => {
    AlertService.alertConfirm(
      `Are you sure you want to delete this file?`, "", `yes`, 'no'
    ).then(() => {
      setUploadedImage(null);
      setFlagImage(null);
    })
  }

  const onSubmit = () => {
    if (!language.trim() || !isoCode.trim() || !isoCode2.trim()) {
      setIsInvalidSubmit(true);
      return;
    }
    const formData = new FormData();
    const form = {
      Name: language.trim(),
      ISOCode: isoCode.trim(),
      ISOCode2: isoCode2.trim(),
      FlagImage: flagIamge
    }

    for (let i in form) {
      formData.append(`${i}`, form[i]);
    }
    setIsShowModalSpinner(true);
    ApiService.createLanguage(formData).then(() => {
      setIsShowModalSpinner(false);
      cancel();
    }).catch(error => getFail(error))
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    dispatch(removePageSpinner(spinnerId));
    setIsShowModalSpinner(false);
  };

  return translationService ? <React.Fragment>
    <div className="page-content">
      <MetaTags>
        <title>Languages</title>
      </MetaTags>
      <Container fluid={true}>
        <Breadcrumbs title="Languages" breadcrumbItem="Languages" />
        <div className='my-2'>
          <div className='d-flex justify-content-end'>
            <button className="btn btn-outline-primary" type="button" onClick={() => setIsShowModal(true)}>
              Create Language
            </button>
          </div>
        </div>

        {
          isShowModal ?
            <ModalComponent
              title={`${languageId ? "Update Language" : "Create Language"}`}
              actionButtonTitle={`${languageId ? "Update" : "Create"}`}
              isShowModalSpinner={isShowModalSpinner}
              isLarge={true}
              onSubmit={onSubmit}
              cancel={cancel}
            >
              <Row>
                <Col className='col-12'>
                  <div className="mb-3 m-2">
                    <Label htmlFor="language">Name *</Label>
                    <Input
                      type="text"
                      className={`form-control ${isInvalidSubmit && (!language || !language.trim()) ? "error-border" : ""}`}
                      id="language"
                      value={language}
                      onChange={(event) => onChange(event, setLanguage, languagemaxLength)}
                    />
                  </div>
                </Col>
                <Col className='col-12'>
                  <div className="mb-3 d-sm-flex">
                    <div className='m-2'>
                      <Label htmlFor="isoCode">ISO code *</Label>
                      <Input
                        type="text"
                        className={`form-control ${isInvalidSubmit && (!isoCode || !isoCode.trim()) ? "error-border" : ""}`}
                        id="isoCode"
                        value={isoCode}
                        onChange={(event) => onChange(event, setIsoCode, isoCodeMaxLength)}
                      />
                    </div>
                    <div className='m-2'>
                      <Label htmlFor="formrow-firstname-Input">ISO code 2 *</Label>
                      <Input
                        type="text"
                        className={`form-control ${isInvalidSubmit && (!isoCode2 || !isoCode2.trim()) ? "error-border" : ""}`}
                        id="isoCode2"
                        value={isoCode2}
                        onChange={(event) => onChange(event, setIsoCode2, isoCode2Maxlength)}
                      />
                    </div>
                  </div>
                </Col>
                <Col className='col-12'>
                  <div className="m-2">
                    <Label htmlFor="formrow-firstname-Input ">Flag *</Label>
                    {
                      uploadedImage ?
                        <div className="dropzone-previews mb-3 upload-avatar-container" id="file-previews">
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          >
                            <div className="p-2">
                              <Row className="align-items-center justify-content-between">
                                <Col className="col-auto">
                                  <div className='upload-avatar-block'>
                                    <img
                                      data-dz-thumbnail=""
                                      className="avatar-sm rounded bg-light"
                                      src={uploadedImage}
                                    />
                                    <span className='remove-file-icon d-flex justify-content-end remove-avatar-file'>
                                      <i className="bx bx-trash label-icon red-color" onClick={removeFile} />
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </div>
                        : <input type="file" onChange={uploadImage} />
                    }
                  </div>
                </Col>
              </Row>
            </ModalComponent>
            : null
        }
        {
          languages && languages.length ?
            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      <Table className="table table align-middle table-nowrap table-hover">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>ISO code</th>
                            <th>ISO code 2</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            languages.map((language) => <tr key={language.languageId} className='cursor-default'>
                              <th scope="row">{language.id}</th>
                              <td>{language.name}</td>
                              <td>{language.isocode}</td>
                              <td>{language.isocode2}</td>
                              <td>
                                <div className="d-flex gap-3">
                                  <a className="text-success" href="#" onClick={() => getLanguageById(language.languageId)}>
                                    <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
                                  </a>
                                  <a className="text-danger" href="#" onClick={() => removeLanguage(language)}>
                                    <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
                                  </a>
                                </div>
                              </td>
                            </tr>
                            )
                          }
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <div className="row mt-2">
                <Col className="col-12">
                  <div>
                    <ReactPaginate
                      nextLabel={"next"}
                      // onPageChange={this.handlePageClick}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={10}
                      previousLabel={"prev"}
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                      forcePage={0}
                    />
                  </div>
                </Col>
              </div>
            </Row>
            : <div>
              <hr />
              <p>You don't have any created languages</p>
            </div>
        }

      </Container>
    </div>
  </React.Fragment> : null
}

export default withRouter(Languages)
