export const TOKEN_KEY = 'token';
export const LANGUAGE_KEY = 'language';
export const LANGUAGES_KEY = 'languages';
export const DEFAULT_LANGUAGE_KEY = 'en';
export const USER_KEY = 'user';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const TRANSLATE_KEYS = 'translatekeys';
export const OBJECT_KEY = 'object';
export const INFO_KEY = "info";
export const ERROR_KEY = 'error';
export const SUCCESS_KEY = "success";
export const WARNING_KEY = "warning";
export const INVALID_USERNAME_OR_PASSWORD = "invalid_username_or_password";
export const NUMBER_KEY = "number";
export const SPINNER_COLOR = "#1081E8";


export const IMAGE_KEY = 'image';
export const FILE_KEY = 'file';
export const VIDEO_KEY = 'video';

export const VALID_IMAGE_TYPES_KEY = ['jpeg', 'png', 'jpg', 'JPEG', 'PNG', 'JPG'];
