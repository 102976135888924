import React, { useState } from 'react';
import MetaTags from 'react-meta-tags'
import SweetAlert from "react-bootstrap-sweetalert"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import Breadcrumbs from './../../../../components/Common/Breadcrumb'
import FileUploadModal from './Components/FileUploadModal'
import AlertService from './../../../../Services/alertService'
import ApiService from './../../../../Services/apiService';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  UncontrolledAlert,
  Container,
  Table,
} from "reactstrap"
import ReactPaginate from 'react-paginate';


const FileLibrary = (props) => {

  const [confirm_alert, setconfirm_alert] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_description, setdynamic_description] = useState("")

  const [isShowModal, setIsShowModal] = useState(false)
  const [isShowModalSpinner, setIsShowModalSpinner] = useState(false)
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false)
  const [isInvalidFileUploadSubmit, setIsInvalidFileUploadSubmit] = useState(false)


  const cancel = () => {
    setIsShowModal(false)
    setIsInvalidSubmit(false)
  }

  const copyUrl = () => {
    AlertService.alert("success", "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, ")
  }

  const deleteFile = () => {
    AlertService.alertConfirm(
      `Are you sure ?`, "", `yes`, 'no'
    ).then(() => {
      console.log("file deleted");
    })
  }

  const onSubmit = (data) => {
    if (!data.length) {
      setIsInvalidFileUploadSubmit(true)
    }
    setIsShowModalSpinner(true)
    setTimeout(() => {
      setIsShowModalSpinner(false)
    }, 3000);
  }

  return <React.Fragment>
    <div className="page-content">
      <MetaTags>
        <title>{props.t("File Library")}</title>
      </MetaTags>
      <Container fluid={true}>
        <Breadcrumbs title="File Library" breadcrumbItem="File Library" />
        <div className='my-2'>
          <div className='d-flex justify-content-end'>
            <button className="btn btn-outline-primary" type="button" onClick={() => setIsShowModal(true)}>
              Add File
            </button>
          </div>
        </div>
        <hr />
        {
          isShowModal ?
            <FileUploadModal
              isShowModalSpinner={isShowModalSpinner}
              onSubmit={onSubmit}
              cancel={cancel}
              isInvalidFileUploadSubmit={isInvalidFileUploadSubmit}
              setIsInvalidFileUploadSubmit={setIsInvalidFileUploadSubmit}
            />
            : null
        }

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <div className="table-responsive file-library-table">
                  <Table className="table table align-middle table-nowrap table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>File</th>
                        <th>URL</th>
                        <th>Username</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='cursor-default'>
                        <th scope="row">1</th>
                        <td><img src='https://upload.wikimedia.org/wikipedia/commons/3/3c/IMG_logo_%282017%29.svg' alt='/' /></td>
                        <td>Otto</td>
                        <td>@mdo</td>
                        <td>
                          <div className="d-flex gap-3">
                            <Link className="text-success d-flex align-items-center" to="#" onClick={copyUrl}>
                              <i className="bx bx-copy font-size-18" id="edittooltip"></i>
                            </Link>
                            <Link className="text-danger" to="#" onClick={deleteFile}>
                              <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                      <tr className='cursor-default'>
                        <th scope="row">2</th>
                        <td><img src='https://imgv3.fotor.com/images/blog-cover-image/part-blurry-image.jpg' alt='/' /></td>
                        <td>Otto</td>
                        <td>@mdo</td>
                        <td>
                          <div className="d-flex gap-3">
                            <Link className="text-success d-flex align-items-center" to="#" onClick={copyUrl}>
                              <i className="bx bx-copy font-size-18" id="edittooltip"></i>
                            </Link>
                            <Link className="text-danger" to="#" onClick={deleteFile}>
                              <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                      <tr className='cursor-default'>
                        <th scope="row">3</th>
                        <td><img src='https://www.kraftwerk.at/app/uploads/fly-images/962/reference-img-worlds-of-adventure-park-4-1920x9999.jpg' alt='/' /></td>
                        <td>Otto</td>
                        <td>@mdo</td>
                        <td>
                          <div className="d-flex gap-3">
                            <Link className="text-success d-flex align-items-center" to="#" onClick={copyUrl}>
                              <i className="bx bx-copy font-size-18" id="edittooltip"></i>
                            </Link>
                            <Link className="text-danger" to="#" onClick={deleteFile}>
                              <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <div className="row mt-2">
            <Col className="col-12">
              <div>
                <ReactPaginate
                  nextLabel={"next"}
                  // onPageChange={this.handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={10}
                  previousLabel={"prev"}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                  forcePage={0}
                />
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </div>

    <Col xl={3} lg={4} sm={6} className="mb-2">
      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false)
            setsuccess_dlg(true)
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            setsuccess_dlg(false)
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
    </Col>
  </React.Fragment>
}

FileLibrary.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(FileLibrary))
