
import { takeLatest, call, put } from 'redux-saga/effects';
import uuid from 'react-uuid';
import { GET_LANGUAGES_SUCCESS, GET_LANGUAGES_REQUEST } from "./actionTypes";
import AlertService from '../../Services/alertService';
import { addPageSpinner, removePageSpinner } from '../../store/spinner/actions';
import ApiService from '../../Services/apiService';
import { ERROR_KEY } from '../../Constants/MainKeys';

export default function* watcher() {
  yield takeLatest(GET_LANGUAGES_REQUEST, getLanguages);
};

function* getLanguages() {
  const spinnerId = uuid();
  try {
    yield put(addPageSpinner(spinnerId));
    const languages = yield call(ApiService.getLanguages);
    yield put({
      type: GET_LANGUAGES_SUCCESS,
      payload: languages && languages.data,
    });
    yield put(removePageSpinner(spinnerId));
  } catch (error) {
    yield put(removePageSpinner(spinnerId));
    error && AlertService.alert((AlertService.checkMessageType(error?.respcode) || ERROR_KEY), error);
  };
};


