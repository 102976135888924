import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom"
import { DEFAULT_LANGUAGE_KEY } from '../../Constants/MainKeys'
import { useSelector } from 'react-redux'

import Dashboard from '../../pages/Admin-Pages/Dashboard/Dashboard'
import Notifications from "../../pages/Admin-Pages/Notifications/Notifications"
import Logout from '../../pages/Authentication/Logout'
import Pages from '../../pages/Admin-Pages/Page/Pages'
import PageForm from '../../pages/Admin-Pages/Page/PageForm'
import PageContentForm from '../../pages/Admin-Pages/Page/PageContentForm'
import PageContents from '../../pages/Admin-Pages/Page/PageContents'
import Profile from "../../pages/Authentication/Profile"
import Translations from '../../pages/Admin-Pages/Translation/Translations';
import Languages from '../../pages/Admin-Pages/System-Options/Languages/Languages';
import PaymentPackages from '../../pages/Admin-Pages/System-Options/Payment-Packages/PaymentPackages';
import PaymentPackageForm from '../../pages/Admin-Pages/System-Options/Payment-Packages/PaymentPackageForm';
import Roles from '../../pages/Admin-Pages/System-Options/Roles/Roles';
import Sections from '../../pages/Admin-Pages/Content-Managment/Sections/Sections';
import FileLibrary from '../../pages/Admin-Pages/Content-Managment/FileLibrary/FileLibrary';
import SectionForm from '../../pages/Admin-Pages/Content-Managment/Sections/SectionForm';
import UserTypes from '../../pages/Admin-Pages/System-Options/UserTypes/UserTypes';
import PaymentSystems from '../../pages/Admin-Pages/System-Options/Payment-Systems/PaymentSystems';
import PaymentSystemForm from '../../pages/Admin-Pages/System-Options/Payment-Systems/PaymentSystemForm';


export default function AuthMiddleware() {

  const { language } = useSelector(state => state.Languages);

  return (
    <Switch>
      <Route path="/:language/dashboard" exact component={Dashboard} />
      <Route path="/:language/notifications" exact component={Notifications} />
      <Route path="/:language/pages" exact component={Pages} />
      <Route path="/:language/profile" exact component={Profile} />
      <Route path="/:language/pages/form" exact component={PageForm} />
      <Route path="/:language/pages/form/:pageId" exact component={PageForm} />
      <Route path="/:language/pages/content/:pageId" exact component={PageContents} />
      <Route path="/:language/pages/content-form" exact component={PageContentForm} />
      <Route path="/:language/pages/content-form/:pageId" exact component={PageContentForm} />
      <Route path="/:language/pages/content-form/:pageId/:contentId" exact component={PageContentForm} />
      <Route path="/:language/pages/content-form/:pageId/:contentId/:languageId" exact component={PageContentForm} />

      <Route path="/:language/languages" exact component={Languages} />
      <Route path="/:language/translations" exact component={Translations} />
      <Route path="/:language/payment-packages" exact component={PaymentPackages} />
      <Route path="/:language/payment-packages/form" exact component={PaymentPackageForm} />
      <Route path="/:language/payment-packages/form/:packageId" exact component={PaymentPackageForm} />
      <Route path="/:language/sections" exact component={Sections} />
      <Route path="/:language/sections/form" exact component={SectionForm} />
      <Route path="/:language/sections/form/:sectionId" exact component={SectionForm} />
      <Route path="/:language/file-library" exact component={FileLibrary} />
      <Route path="/:language/roles" exact component={Roles} />
      <Route path="/:language/user-types" exact component={UserTypes} />
      <Route path="/:language/payment-system" exact component={PaymentSystems} />
      <Route path="/:language/payment-system/form" exact component={PaymentSystemForm} />
      <Route path="/:language/payment-system/form/:systemId" exact component={PaymentSystemForm} />


      <Route path="/:language/logout" exact component={Logout} />
      <Redirect to={`/${language ? language : DEFAULT_LANGUAGE_KEY}/dashboard`} />

    </Switch>
  )
}
