import { LANGUAGE_SUCCESS, GET_LANGUAGES_REQUEST } from "./actionTypes";

export function setLanguage(language) {
  return {
    type: LANGUAGE_SUCCESS,
    payload: language,
  };
};

export function getLanguages() {
  return {
    type: GET_LANGUAGES_REQUEST,
    payload: {},
  };
};
