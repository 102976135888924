import React, { useState } from 'react';
import ModalComponent from '../../../../../components/AdminComponents/Modal/ModalComponent';
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { withRouter } from 'react-router-dom'
import Dropzone from "react-dropzone"
import { Link } from 'react-router-dom'
import SweetAlert from "react-bootstrap-sweetalert"
import AlertService from './../../../../../Services/alertService';
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  Container,
} from "reactstrap"
import { VALID_IMAGE_TYPES_KEY } from "./../../../../../Constants/MainKeys"

const FileUploadModal = (props) => {

  const [selectedFiles, setselectedFiles] = useState([])

  const handleAcceptedFiles = (files) => {
    props.setIsInvalidFileUploadSubmit(false);
    const checkFiles = checkFilesType(files);
    checkFiles.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(checkFiles)
  }

  const checkFilesType = (files) => {
    const checkedFiles = [];
    for (const i in files) {
      const file = files[i];
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';
      if (VALID_IMAGE_TYPES_KEY.includes(fileExtention || fileExtention.toUpperCase())) {
        checkedFiles.push(file);
      }
    }
    return checkedFiles;
  }

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const removeFile = (currentFile) => {
    if (!currentFile) { return; }
    AlertService.alertConfirm(
      `Are you sure you want to delete this file?`, "", `yes`, 'no'
    ).then(() => {
      const updatedSelectedFiles = selectedFiles.filter(file => file.name !== currentFile.name);
      setselectedFiles(updatedSelectedFiles);
      AlertService.alert("success", "Your file has been successfully deleted.")
    })
  }

  return <ModalComponent
    isLarge={true}
    title="Upload files"
    actionButtonTitle="Save"
    isShowModalSpinner={props.isShowModalSpinner}
    onSubmit={() => props.onSubmit(selectedFiles)}
    cancel={props.cancel}
  >
    <Container fluid={true}>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody className={`${props.isInvalidFileUploadSubmit ? "error-border" : ""}`}>
              <CardTitle>Dropzone</CardTitle>
              <Form>
                <Dropzone
                  onDrop={acceptedFiles => {
                    handleAcceptedFiles(acceptedFiles)
                  }}
                  acceptedFiles=".png"
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-2"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h4>{props.t("Drop files here or click to upload.")}</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="dropzone-previews mt-3" id="file-previews">
                  {selectedFiles.map((f, i) => {
                    return (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center justify-content-between">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold"
                              >
                                {f.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                            <Col>
                              <span className='remove-file-icon d-flex justify-content-end'>
                                <i className="bx bx-trash label-icon red-color" onClick={() => removeFile(f)} />
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )
                  })}
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </ModalComponent >
}

FileUploadModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(FileUploadModal))