import React, { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router } from "react-router-dom"
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// import fakeBackend from "./helpers/AuthType/fakeBackend"
import PageSpinner from './components/Spinners/PageSpinner'
import { addPageSpinner, removePageSpinner } from './store/spinner/actions'
import uuid from 'react-uuid'
import "./assets/scss/theme.scss"
import { getTranslations } from "./store/translations/actions"
import { getLanguages } from "./store/language/actions"
import UnauthMiddleware from "./routes/middleware/UnauthMiddleware"
import TranslationService from "./Services/translationService"
import AuthMiddleware from "./routes/middleware/Authmiddleware"

// fakeBackend()


const App = () => {

  const dispatch = useDispatch()
  const { user } = useSelector(state => state.Login);
  const { language } = useSelector(state => state.Languages);
  const { translations } = useSelector(state => state.Translations);
  const { pageSpinners } = useSelector(state => state.Spinners);
  const layout = useSelector(state => state.Layout);

  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    dispatch(getLanguages());
    language && dispatch(getTranslations());
  }, [])

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const Layout = getLayout()

  return (
    <React.Fragment>
      <Router>
        {
          user ?
            <Layout>
              <AuthMiddleware />
            </Layout>
            :
            <NonAuthLayout>
              <UnauthMiddleware />
            </NonAuthLayout>
        }
      </Router>
      <PageSpinner spinner={pageSpinners} />
      <div id="cx-alert-container" className='cx-alert-container' />
    </React.Fragment>
  )
}

export default App
