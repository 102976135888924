import React from 'react';
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  UncontrolledAlert,
  Container,
  Table,
  Label,
  Input,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import Breadcrumbs from './../../../../components/Common/Breadcrumb'
import ReactPaginate from 'react-paginate'
import { useSelector } from 'react-redux';

const PaymentPackages = (props) => {

  const language = useSelector(state => state.Languages.language);

  return <React.Fragment>
    <div className="page-content">
      <MetaTags>
        <title>Payment Packages</title>
      </MetaTags>
      <Container fluid={true}>
        <Breadcrumbs title="Payment Packages" breadcrumbItem="Payment Packages" />
        <div className='my-2'>
          <div className='d-flex justify-content-end'>
            <button className="btn btn-outline-primary" type="button" onClick={() => props.history.push(`/${language}/payment-packages/form`)}>
              Create Payment Package
            </button>
          </div>
        </div>
        <hr />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Table className="table table align-middle table-nowrap table-hover">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Cost</th>
                        <th>User Max Count</th>
                        <th>Storage Size</th>
                        <th>Allow Save Video</th>
                        <th>Quality</th>
                        <th>Face Detection Analyzer</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='cursor-default'>
                        <th>Bronze</th>
                        <td>Includes basic access</td>
                        <td>20$</td>
                        <td>10</td>
                        <td>30 MB</td>
                        <td>Yes</td>
                        <td>HD</td>
                        <td>No</td>
                        <td>
                          <div className="d-flex gap-3">
                            <a className="text-success" href="#">
                              <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
                            </a>
                            <a className="text-danger" href="#">
                              <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr className='cursor-default'>
                        <th>Silver</th>
                        <td>Our best seller! Enjoy!!</td>
                        <td>30$</td>
                        <td>15</td>
                        <td>40 MB</td>
                        <td>Yes</td>
                        <td>HD</td>
                        <td>No</td>
                        <td>
                          <div className="d-flex gap-3">
                            <a className="text-success" href="#">
                              <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
                            </a>
                            <a className="text-danger" href="#">
                              <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr className='cursor-default'>
                        <th>Gold</th>
                        <td>Unrestricted access</td>
                        <td>50$</td>
                        <td>20</td>
                        <td>50 MB</td>
                        <td>Yes</td>
                        <td>Full HD</td>
                        <td>Yes</td>
                        <td>
                          <div className="d-flex gap-3">
                            <a className="text-success" href="#">
                              <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
                            </a>
                            <a className="text-danger" href="#">
                              <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <div className="row mt-2">
            <Col className="col-12">
              <div>
                <ReactPaginate
                  nextLabel={"next"}
                  // onPageChange={this.handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={10}
                  previousLabel={"prev"}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                  forcePage={0}
                />
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}

export default withRouter(PaymentPackages)
